import { Component } from "@angular/core";

@Component({
  selector: "mag-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "matt-a-green";
}
